import { useContext } from 'react';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { OnTimePickupAssoc } from './OnTimePickupAssoc';
import { useQueryKeys } from '../../../../../../components/ShrinkContainer/hooks/useQueryKeys';
import { DataView } from '../../../../../../components/Common/DataView';
import { onTimePickUpBuilder } from '../../../../utils/onTimePickUpBuilder';
import { divide } from '../../../../../../utils/numbers';

export const OnTimePickup = () => {
    const { orderFulfillmentPickupData, strNbr, timeframe } = useContext(StoreOrderFulfillmentContext);
    const [storeOnTimePickUp, curbsidePickUp] = useQueryKeys(['storeOnTimePickUp', `query/queryPickUpCurbside/store/${strNbr}`])
    const totalRow = storeOnTimePickUp.filter(row => row.CHANNEL === 'TOTAL')
    const bodyRows = storeOnTimePickUp.filter(row => row.CHANNEL !== 'TOTAL')
    const pickupMethodsDataObj = {
        headers: [
            { name: 'Method' },
            { name: 'Pick On Time %' },
            { name: '# of Orders Missed SLA' },
            { name: 'Avg Min to Pick' },
            { name: 'DS Compliance %' }
        ],
        ...onTimePickUpBuilder({ bodyRows, totalRow, timeframe })
    }

    const curbsideDataObj = {
        headers: [
            { name: 'Method' },
            { name: 'Avg Customer Wait Time' },
            { name: '# of Orders Missed SLA' },
            { name: '% of Orders Missed SLA' }
        ],
        data: curbsidePickUp.map(data => [
            { stringValue: 'Curbside' },
            {
                numValue: +data[timeframe + '_AVG_WAIT_TIME'],
                decimals: 2
            },
            { numValue: data[timeframe + '_MISS_SLA'] },
            { pctValue: divide(data[timeframe + '_MISS_SLA'], data[timeframe + '_TOTAL_ORD']) },
        ])
    };

    return (
        <DataView className='my-4'>
            <DataView.Title as='h3'>Pickup Details by Method</DataView.Title>
            <DataView.DataTable data={pickupMethodsDataObj} />
            <DataView.Title as='h3'>Pickup Details for Curbside</DataView.Title>
            <DataView.DataTable data={curbsideDataObj} />
            <DataView.Title as='h3'>More Details</DataView.Title>
            <OnTimePickupAssoc />
        </DataView>
    );
};



